/* yellow: #FBC02D*/
html, body {
  height:100%;
  overflow-x: hidden;
}

body {
    margin: 0;
    position: relative;
    background-color: #eeee;
    font-family: 'Nunito' !important;
}

#stat-panel {
  font-family: 'Nunito' !important;
  background-color: white;
  margin-top: 20px;
  border-radius: 8px;
  width: 90%;
  padding: 30px;
  display: table;
}

#stat-panel-mobile {
  display: none;
  font-family: 'Nunito' !important;
  background-color: white;
  border-radius: 8px;
  width: 80%;
  margin-top: 20px;
  padding: 30px;
}

#stat-total { 
  width: 25%;
  display: inline-table;
  text-align: center;
}

#stat-number {
  font-weight: bold;
  font-size: xx-large;
}

.styles_react-code-input__CRulA input {
  height: 40px !important;
  width: 40px !important;
}

.MuiTypography-colorTextSecondary {
  height: 73px !important;
  margin-bottom: 0px !important;
}

.pop-link {
  cursor: pointer !important;
  color: black !important;
  font-weight: bold !important;
  text-decoration: none !important;
}

.styles_react-code-input-container__tpiKG {
  width: initial !important;
  margin: 20px !important;
  font-family: 'Nunito' !important;
}

.logoutBtn {
  top: -15px;
  margin-right: 15px !important;
  font-weight: 700 !important;
  width: 30px !important;
}

.logoutBtn >  div > span {
  font-weight: 700 !important;
}

.MuiDialog-paper {
  width: 50% !important;
  max-width: none !important;
}

.MuiDialog-paperWidthSm {
  max-width: none !important;
}

.grecaptcha-badge { opacity:0;}

#unique-card {
    width: 90% !important;
    height:780px !important;
    margin-top: 150px !important;
    margin: auto;
    max-height: 1406px;
    max-width: 1000px !important;
    margin-bottom: 200px !important;
}

#nav-post {
  display: none !important;
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
}

#mobile-filter-icon {
  display: none !important;
}

#web-filter-row {
  display: block !important;
  width: 90% !important;
  padding-left: 0px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

#filterRow {
  height: 40px;
  left: 40px;
  margin-top: 30px;
  width: 700px;
  padding-top: 0px;
  padding-left: 0px !important;
  position: static;
}

#line-progress {
    top: 64px;
    position: absolute;
    left: 0px;
    width: 100%;
    background-color: white;
}

.rc-anchor .rc-anchor-invisible .rc-anchor-light .rc-anchor-invisible-hover {
    display: none !important;
}

.MuiLinearProgress-barColorPrimary {
    background-color: #e0e0e0 !important;
}

.MuiOutlinedInput-input:-webkit-autofill {
    background-color: lightcoral !important;
    color: lightcoral !important;
}

.MuiBottomNavigationAction-root.Mui-selected {
    color: #FBC02D !important;
    outline: 0px !important;
    z-index: 1294989 !important;
    border: none !important;
}

.MuiInputBase-input:focus {
    outline: 0px !important;
    z-index: 1294989 !important;
    border: none !important;
}

.MuiInputBase input {
    border: none !important;
}

.image {
    margin-top: 10px !important;
    width: 100%;
    height: 425px;
    overflow: hidden !important;
}

/* .bottom-nav {
    display: none !important;
    position: fixed !important;
    bottom: 0px !important;
    width: 100%;
    background-color: #212121 !important;
    z-index: 10000000023094;
    color: white !important;
} */

.no-results {
  margin-left: 32px !important;
}

.mc-layout__bannerContent {
    display: none;
}

h3 {
    margin-bottom: 5px !important;
    color: #212121 !important;
}

#char-count {
  font-size: 11px;
  position: relative;
  left: 356px;
  font-family: Roboto !important;
  bottom: 9px;
}

.half-inputs {
    width: 48.5%;
    margin-top: 20px !important;
    margin-right: 8px !important;
}

.full-inputs {
    width: 100%;
    margin-top: 20px !important;
    margin-right: 8px !important;
}

.half-inputs-right {
    width: 48%;
    margin-top: 20px !important;
    margin-left: 8px !important;
}

#filterIcon:hover {
    cursor: pointer !important;
}

#filterIcon {
    width: 30px !important;
    margin-top: 20px;
}

.cardImage:hover {
    cursor: pointer !important;
}

#sort-pagination {
    position: absolute;
    width: 90%;
    left: 40px;
    top: 75px;
}

#sort-pagination-analytics {
  position: absolute;
  width: 90%;
  left: 40px;
  top: 355px;
}

.MuiPaper-elevation16 {
  width: 200px;
}

.cards-analytics {
  align-items: center;
  min-width: 545px;
  display: flow-root;
  margin-bottom: 100px !important;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.fileContainer {
    background-color: #e0e0e0 !important;
}

#edit-mobile-only {
    display: none;
    position: relative;
    top: 20px;
    margin-right: 20px;
    height: 20px;
    width: 20px;
    float: right;
    text-align: center;
    padding: 5px;
    vertical-align: middle;
    color: white;
    background-repeat: no-repeat;
    border-radius: 50%;
    background-size: auto;
    background-position: center;
    background-image: url("./static/edit.svg");
    background-color: rgba(33, 33, 33, 0.8);
}

.chooseFileButton {
    height: 40px !important;
}

.zoomBtn {
    position: relative;
    top: 20px;
    margin-right: 20px;
    height: 20px;
    width: 20px;
    float: right;
    text-align: center;
    padding: 5px;
    vertical-align: middle;
    color: white;
    background-repeat: no-repeat;
    border-radius: 50%;
    background-size: auto;
    background-position: center;
    background-image: url("./static/zoom.svg");
    background-color: rgba(33, 33, 33, 0.8);
}

.editBtn {
    position: relative;
    top: 20px;
    margin-right: 20px;
    height: 20px;
    width: 20px;
    float: right;
    text-align: center;
    padding: 5px;
    vertical-align: middle;
    color: white;
    background-repeat: no-repeat;
    border-radius: 50%;
    background-size: auto;
    background-position: center;
    background-image: url("./static/edit.svg");
    background-color: rgba(33, 33, 33, 0.8);
}

.starBtn {
    padding: 5px;
    width: 25px;
    margin-top: 12px;
    cursor: pointer;
    color: #212121;
}

.iconNav {
    padding: 10px;
    cursor: pointer;
}

#cameraBtn {
    z-index: 1124;
    padding: 10px;
    background-color: #FBC02D;
    width: 40px;
    height: 40px;
    bottom: 84px;
    right: 20px;
    border-radius: 50%;
    position: fixed !important;
    cursor: pointer;
    box-shadow: 0px 3px 2px -1px rgba(0, 0, 0, 0.2), 1px 1px 3px 3px rgba(0, 0, 0, 0.14), 0px 2px 4px 1px rgba(0, 0, 0, 0.12);
    transition: bottom 0.9s;
}

.containerRight {
    margin-top: 10px !important;
    width: 100%;
    height: 425px;
}

.image>div {
    width: 100% !important;
}

.gagunkbtn {
    position: relative !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    vertical-align: middle !important;
    letter-spacing: 0px !important;
    width: 20px !important;
    text-transform: uppercase !important;
    font-weight: 500 !important;
    font-size: 14px !important;
}

#authLoader {
    position: relative !important;
    bottom: 32px !important;
}

.react-tel-input {
  font-family: 'Roboto',sans-serif;
  font-size: 15px;
  position: relative;
  width: 50% !important;
  height: 45px !important;
  min-width: 88px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 20px !important;
  margin-bottom: 10px !important;
}

.react-tel-input > .form-control {
  height: 45px !important;
  width: 100% !important;
}

.country-list {
  text-align: left !important;
  margin-top: 2px !important;
}

.MuiCardMedia-root {
  height: 302px !important;
  background-position: center center !important;
}

.expirelabel-unique {    
    position: relative;
    top: -25px;
    padding-right: 6px;
    padding-left: 0px !important;
    left: -14px
}

.imageloader-loading  {
  height: 650px !important;
} 

#unique-card .MuiCardMedia-root {
  height: 650px !important;
  background-position: center center !important;
}

#loginLoader {
    position: relative !important;
    bottom: 22px !important;
}

#blurb {
  display: block !important;
}

.fileContainer .chooseFileButton {
    padding: 6px 23px;
    background: #212121 !important;
    color: white;
    border-radius: 0% !important;
    font-weight: 300;
    font-size: 14px;
    text-transform: uppercase !important;
    margin: 10px 0;
    transition: all 0.2s ease-in;
    cursor: pointer;
    outline: none;
    border: none;
}

.gagunkbtn-submit {
    margin-top: 5px !important;
    margin-right: 0px !important;
    margin-bottom: 20px !important;
    border-radius: 4px !important;
    width: 100%;
    padding: 13px !important;
    height: 55px !important;
    background-color: #FBC02D !important;
    color: white !important;
}

.gagunkbtn-submit > span {
  top: -3px !important;
}

.content__monkeyRewards {
    display: none !important;
}

.modalContent {
    position: inherit !important;
}

.gagunkbtn-submit-disabled {
    margin-top: 5px !important;
    margin-right: 0px !important;
    margin-bottom: 20px !important;
    border-radius: 4px !important;
    width: 100%;
    padding: 13px !important;
    height: 55px !important;
    background-color: lightgrey !important;
    color: white !important;
}

#email {
    border-color: #61dbfb !important;
}

.cardContainer {
    float: left;
    margin-bottom: 20px;
    margin-left: 30px;
    margin-right: 20px;
    display: inline-grid;
}

.breadcrumbs {
    position: absolute;
    top: 84px;
    padding-left: 29px;
}

.MuiBreadcrumbs-li>p {
    font-size: 12px !important;
}

.breadcrumbs>ol {
    font-size: 12px !important;
}

.contestContainer {
    max-width: 331px;
    color: #212121 !important;
    min-width: 331px;
    float: left;
    max-height: 545px;
    margin-bottom: 20px;
    margin-left: 30px;
    margin-right: 20px;
    display: inline-grid;
}

.cards {
    align-items: center;
    min-width: 545px;
    display: flow-root;
    margin-bottom: 100px !important;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 175px !important;
}

@media only screen and (max-width: 1286px) {
  .cards {width: 90% !important;}
  #web-filter-row {
    width: 90% !important;
    padding-left: 0px !important;
  }
}

@media only screen and (max-width: 1009px) {
  .admin-modal {
    width: 80% !important;
  }

  #admin-modal .MuiDialog-paper {
      margin: 0px !important;
      width: 100% !important;
      max-width: none !important
  }

}

@media  (min-width: 633px) and (max-width: 1000px) {
  .cardContainer {
    margin-left: 0px !important;
  }
}

@media only screen and (max-width: 1000px) {
  .cardContainer {
    width: 100% !important;
  }
 #stat-panel {
    display:  none;
  }
  #stat-panel-mobile {
    display:  block;
  }
  #post-card {width: 100% !important;}

  #mobile-filter-icon {
    display: block !important;
  }

  #web-filter-row {
    display: none !important;
    padding-left: 0px !important;
  }
}

@media only screen and (max-width: 970px) {
  #login-cover-image {
    display: none !important;
  }
  #login-right {
      display: block !important;
      width: 100% !important;
      float: left !important;
  }
  .gagunkLogin {
    width: 100% !important;
    max-width: none !important;
    height: 100% !important;
}
}

@media only screen and (max-width: 632px) {
    .cards {
        min-width: 300px !important;
        /* width: 100% !important;  */
        margin-top: 175px;
        margin-left: initial !important;
        margin-bottom: 100px !important;
    }

    .MuiTypography-colorTextSecondary {
      height: auto !important;
    }

    .cards-analytics {
        min-width: initial !important;

    }
    .cards-analytics .cardContainer {
      margin-bottom: 20px !important;
      margin-left: 8px !important;
      margin-right: auto !important; 
    }
    .cardContainer {
      margin-left: 30px;
    }

    #blurb {
      display: none !important;
    }

    #snack {
      display: none !important;
    }

    #admin-modal .MuiDialog-paper {
      margin: 0px !important;
      width: 100% !important;
      max-width: none !important
    }

    .submitBtn {
      margin-bottom: 30px !important;
    }

    #unique-card {
        width: 90% !important;
    }
   
    .MuiDialog-paper {
        margin-top: 0px !important;
    }

    #edit-mobile-only {
        display: inline-flex;
        position: relative;
        top: 20px;
        margin-right: 20px;
        height: 20px;
        width: 20px;
        float: right;
        text-align: center;
        padding: 5px;
        vertical-align: middle;
        color: white;
        background-repeat: no-repeat;
        border-radius: 50%;
        background-size: auto;
        background-position: center;
        background-image: url("./static/edit.svg");
        background-color: rgba(33, 33, 33, 0.8);
    }
    .MuiProjectCard--01 {
        width: 375px !important;
    }
    .half-inputs {
        width: 100% !important;
        margin-top: 20px !important;
        margin-right: 0px !important;
    }
    .half-inputs-right {
        width: 100% !important;
        margin-top: 20px !important;
        margin-left: 0px !important;
    }
    .no-results {
        margin-left: 25px !important;
    }
    #filterIcon {
        margin-right: 35px;
    }
   
    #cameraBtn {
        display: none !important;
        padding: 10px;
        background-color: #FBC02D;
        width: 40px;
        height: 40px;
        position: absolute;
        bottom: 84px !important;
        right: 50px;
        border-radius: 50%;
        position: fixed !important;
        cursor: pointer;
        box-shadow: 0px 3px 2px -1px rgba(0, 0, 0, 0.2), 1px 1px 3px 3px rgba(0, 0, 0, 0.14), 0px 2px 4px 1px rgba(0, 0, 0, 0.12);
        transition: top 0.3s;
    }
    .card {
        height: 400px !important;
        margin: 2px !important;
        margin-top: 50px !important;
        margin-bottom: 50px !important;
    }
}

#login-cover-image {
    background-image: url('./static/login.jpg');
    width: 60%;
    min-height: 1000px;
    margin-bottom: -9px !important;
    height: 100%;
    max-height: 1800px;
    background-size: cover;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center top;
}

#logo-cover {
    display: block !important;
    position: fixed !important;
    width: 85px !important;
    background-repeat: no-repeat !important;
    height: 26px !important;
    background-image: url('./static/login-logo.svg');
}

.loginButtonContainer {
    background-color: inherit !important;
    box-shadow: none !important;
}

@media only screen and (max-width: 920px) {
    .MuiProjectCard--01 {
        width: 100% !important;
    }

  #nav-post {
    display: flex !important;
  }
    #unique-card {
        width: 90% !important;
    }
    #footerArea {
        display: none !important;
    }
    .cardContainer {
        width: 95% !important;
        max-width: none !important;
    }
    .MuiPaper-root .MuiCard-root .MuiPaper-elevation1 .MuiPaper-rounded {
        bottom: 0px;
        height: 60px !important;
        width: 100%;
        position: absolute;
    }
    .loginWindow {
        height: 100%;
    }
    .gagunkbtn-submit {
        height: 53px !important;
    }
    /* .bottom-nav {
        display: none !important;
        position: fixed !important;
        bottom: 0px !important;
        width: 100%;
        background-color: #212121 !important;
        z-index: 10000000023094;
        color: white !important;
    } */
    #cameraBtn {
        display: none !important;
        padding: 10px;
        background-color: #f8504d;
        width: 40px;
        height: 40px;
        position: absolute;
        bottom: 84px !important;
        right: 50px;
        border-radius: 50%;
        position: fixed !important;
        cursor: pointer;
        box-shadow: 0px 3px 2px -1px rgba(0, 0, 0, 0.2), 1px 1px 3px 3px rgba(0, 0, 0, 0.14), 0px 2px 4px 1px rgba(0, 0, 0, 0.12);
        transition: top 0.3s;
    }
    .gagunkLogin {
        z-index: 100000;
        width: 100% !important;
        padding-top: 130px;
        margin: 0px !important;
        max-width: none !important;
        height: 100% !important;
    }
}

.cardText {
    /* height: 450px; */
    /* width: 55%; */
    display: inline-block;
}

.cardTextRight {
    height: 528px;
    float: right;
    width: 39%;
    display: inline-block;
}

.card {
    overflow: hidden;
    height: 600px;
    margin: 20px;
    margin-bottom: 75px;
}

.leader {
    border: 2px solid #c7ca9a !important;
}

.contestBtn {
    width: 100%;
    height: 35px !important;
    border-radius: 1.5px !important;
    background-color: #FBC02D !important;
    z-index: 1;
    color: white !important;
}

.disabledContestBtn {
    width: 100%;
    height: 35px !important;
    border-radius: 1.5px !important;
    background-color: #212121 !important;
    z-index: 1;
    color: white !important;
}

.submitBtn > div > span {
  font-weight: bold !important;
}

.submitBtn > span {
  font-weight: bold !important;
}

.submitBtn {
    width: 50%;
    height: 55px !important;
    border-radius: 0px !important;
    z-index: 12408;
    font-weight: bold !important;
    color: rgb(30,30,30) !important;
}

.deleteBtn {
  width: 50%;
  margin: 0px !important;
  height: 55px !important;
  border-radius: 0px !important;
  background-color: transparent !important;
  z-index: 12408;
  font-weight: bold !important;
  color: rgb(30,30,30) !important;
}


.deleteBtn > div > span {
  font-weight: bold !important;
}

.deleteBtn > span {
  font-weight: bold !important;
}

.resetBtn {
  vertical-align: bottom !important;
  height: 55px !important;
  border-radius: 0px !important;
  margin-top: -10px !important;
  font-weight: bold !important;
  background-color: transparent !important;
  color: #212121 !important;
}

.resetBtn > div > span {
  font-weight: bold !important;
}

.cancelBtn {
    height: 40px !important;
    border-radius: 0px !important;
    width: 50%;
    background-color: white !important;
    color: black !important;
}

 .MuiProjectCard--01 {
    width: 375px;
} 

body>div:nth-child(7)>div>div:nth-child(1)>div>div>div:nth-child(3) {
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

#strikethrough {
    text-decoration: line-through;
}

#gagunk {
    background-color: #FBC02D !important;
}

#disabled {
    background-color: lightgray !important;
}

#pass {
    background-color: #61dbfb !important;
}

.gagunkNav {
    position: fixed !important;
    top: 0px !important;
    background-color: white !important;
}

#loader {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

#footer {
    font-size: 12px;
    text-decoration: none;
    color: black !important;
}

#footerArea {
    padding: 25px;
    bottom: 0px;
    position: absolute !important;
    left: 0px;
    right: 0px;
    margin-bottom: 0px;
    color: black !important;
    font-family: Arial, Helvetica, sans-serif !important;
}

#footer a {
    text-decoration: none;
    color: black !important;
}


/* Login logo loader --- */

.wrapper {
    width: 100px;
    height: 100px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 3px;
    background-color: lightgray;
}

#post-card {
    scroll-snap-stop: always;
}

.loader {
    border: 8px solid #212121;
    border-radius: 50%;
    border-top: 8px solid #FBC02D;
    width: 80px;
    height: 80px;
    -webkit-animation: spin 1.4s linear infinite;
    animation: spin 1.4s linear infinite;
}

.loader-off {
    border-radius: 50%;
    width: 80px;
    height: 80px;
}

.navLogout {
  background-color: #FBC02D !important;
  text-transform: capitalize !important;
  margin-bottom: 10px;
  margin-left: auto !important;
  color: black !important;
  margin-right: auto !important;
  width: 80%;
  margin-top: 60px !important;
  background-color: #FBC02D !important;
  font-weight: bold;
}

#editor-pick {
  display: block;
  width: 108px;
  position: relative;
  float: left;
  border-radius: 8px;
  font-size: 13px;
  padding: 5px;
  padding-left: 10px;
  top: -3px;
  right: 15px;
}

.wrapper {
    background: url("./static/logo-login.svg") center no-repeat;
    background-size: 60%;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: 920px) {
    .MuiSnackbar-anchorOriginBottomCenter {
        left: 50%;
        bottom: initial !important;
        right: auto;
        top: 68px;
        transform: translateX(-50%);
    }
}

#critiqueBtn {
  width: 90px !important;
  min-width: unset !important;
  height: 35px !important;
  border-radius: 4px !important;
  background-color: white !important;
  margin: 0px !important;
  margin-right: 10px !important;
  font-weight: 700 !important;
  overflow: visible !important;
  color: rgb(30,30,30) !important;
  bottom: 13px !important;
  top: -2px !important;
  float: right;
}

#feedbackBtn {
  width: 120px !important;
  min-width: unset !important;
  height: 35px !important;
  border-radius: 4px !important;
  background-color: white !important;
  margin: 0px !important;
  margin-right: 0px !important;
  font-weight: 700 !important;
  overflow: visible !important;
  color: rgb(30,30,30) !important;
  bottom: 13px !important;
  top: -2px !important;
  float: right;
}

#feedbackBtn span {
  padding: 0px !important;   
  font-weight: bold !important;
}

#critiqueBtn-unique {
  width: 90px !important;
  min-width: unset !important;
  height: 35px !important;
  border-radius: 4px !important;
  background-color: white !important;
  margin: 0px !important;
  margin-right: 30px !important;
  font-weight: 700 !important;
  overflow: visible !important;
  color: rgb(30,30,30) !important;
  bottom: 13px !important;
  top: -55px !important;
  float: right;
}

.analytics-btn span {
  padding-left: 0px !important;
  left: -3px;
}

#critiqueBtn span {
  padding-left: 13px !important;
  font-weight: bold !important;
}

.login-critique span {
  color: lightgray !important;
}

#critiqueBtn-unique span {
  padding-left: 13px !important;
  font-weight: bold !important;
}

 .stats {
    list-style-type: none; 
    padding-left: 0px !important;
 }